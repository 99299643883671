import React, {useEffect, useMemo} from 'react'
import ImportHistory from './ImportHistory'
import IgnorePhoneFormat from './IgnorePhoneFormat'
import SystemAttributes from './SystemAttributes'
import ShortUrlDomains from './ShortUrlDomains'
import AlphaNames from './AlphaNames'
import ImportFile from './ImportFile'
import SMSGateways from './SMSGateways'
import {Link, Switch, useHistory, useLocation, useRouteMatch} from 'react-router-dom'
import {PrivateRoute} from '../components/routes'
import {useTranslation} from "react-i18next";
import {Alert} from "@material-ui/lab";
import {useAuth} from "../Contexts/AuthProvider";

const Settings = () => {
    const { t } = useTranslation();
    const {path, url} = useRouteMatch()
    const history = useHistory()
    const {pathname} = useLocation()
    const { user } = useAuth()

    const tabNameToIndex = useMemo(() => {
        return {
            0: `${url}`,
            1: `${url}/system-attributes`,
            2: `${url}/import-history`,
            3: `${url}/ignore-phone-format`,
            4: `${url}/short-url-domains`,
            5: `${url}/alpha-names`,
        }
    }, [url])

    const indexToTabName = useMemo(() => {
        return {
            [`${path}`]: 0,
            [`${path}/system-attributes`]: 1,
            [`${path}/import-history`]: 2,
            [`${path}/ignore-phone-format`]: 3,
            [`${path}/short-url-domains`]: 4,
            [`${path}/alpha-names`]: 5
        }
    }, [path])

    const [value, setValue] = React.useState(() => {
        return indexToTabName[pathname] !== undefined ? indexToTabName[pathname] : 0
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
        history.push(tabNameToIndex[newValue])
    };

    useEffect(() => {
        const index = indexToTabName[pathname] !== undefined ? indexToTabName[pathname] : 0
        setValue(index)
    }, [pathname, indexToTabName])

    const featureAlphaName = useMemo(() => {
        return user ? user.getFeatureBySlug('alpha-name-limit') : null
    }, [user])

    return (
        <div className="pageHolder">
            {pathname.includes('/alpha-names') && user && Boolean(featureAlphaName) && !user.canUseFeature('alpha-name-limit') &&
                <Alert>
                    {t('Alpha Names.You reached your free alpha names')}&nbsp;
                    {featureAlphaName.feature_remaining !== false &&
                        <>{featureAlphaName.feature_remaining ?? 0}/{featureAlphaName.value ?? 0}&nbsp;</>
                    }
                    <Link to={`/profile/subscriptions`}>{t('Subscriptions.Please upgrade your plan to remove this limitation')}</Link>
                </Alert>
            }

            {/* <Tabs value={value} onChange={handleChange} indicatorColor='primary' className='pageTabs'>
                <Tab label={t("Settings.SMS Gateways")} disableRipple/>
                <Tab label={t("Settings.System Attributes")} disableRipple/>
                <Tab label={t("Settings.Import History")} disableRipple/>
                <Tab label={t("Settings.Ignore Phone Format")} disableRipple/>
                <Tab label={t("Settings.Short Url Domains")} disableRipple/>
                <Tab label={t("Settings.Alpha Names")} disableRipple/>
            </Tabs> */}
            <Switch>
                <PrivateRoute path={`${path}`} component={SMSGateways} exact/>
                <PrivateRoute path={`${path}/system-attributes`}  component={SystemAttributes}/>
                <PrivateRoute path={`${path}/ignore-phone-format`} exact component={IgnorePhoneFormat}/>
                <PrivateRoute path={`${path}/import-history`} exact component={ImportHistory}/>
                <PrivateRoute path={`${path}/import-history/:fileId`} component={ImportFile}/>
                <PrivateRoute path={`${path}/short-url-domains`} component={ShortUrlDomains}/>
                <PrivateRoute path={`${path}/alpha-names`} component={AlphaNames}/>
            </Switch>
        </div>
    )
}

export default Settings
