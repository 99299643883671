import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useAppDispatch} from '../Contexts/AppContext'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {defaultAlphaName, getAlphaNames} from '../api'
import ListTable from '../components/ListTable/ListTable'
import EventEmitter from '../Utils/EventEmitter'
import SaveAlphaNameModal from '../components/modals/SaveAlphaNameModal'
import {useTranslation} from "react-i18next";
import DisabledValue from '../components/DisabledValue';
import PlusCircle from '../assets/img/plus_circle.svg'
import IndeterminateCheckbox from "../components/IndeterminateCheckbox";
import AlertToastr from "../Utils/alert";
import {Alert} from "@material-ui/lab";
import {useAuth} from "../Contexts/AuthProvider";

const AlphaNames = () => {
    const { t } = useTranslation();
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const [pagination] = useState(null)
    const [sorting, setSorting] = useState([])
    const [,setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [list, setList] = useState([])
    const [showCreateModal, setShowCreateModal] = useState(false)
    const queryClient = useQueryClient()

    const defaultAlphaNameRequest = useMutation(defaultAlphaName)
    const setDefaultAlphaName = useCallback(async id => {
        await defaultAlphaNameRequest.mutateAsync({id}).then(data => {
            AlertToastr.showAlert(t('Alpha Names.You have change default alpha name'))
            queryClient.invalidateQueries('alphaNames')
            queryClient.invalidateQueries('smsGateways')
        });
    }, [queryClient, defaultAlphaNameRequest])

    const tableColumns = useMemo(() => {
        return [
            {
                Header: t('Alpha Names.Alpha Name'),
                accessor: 'name',
                Cell: ({value, row}) => row.original.status == 0 ? <DisabledValue value={value} /> : <span className={row.original.default ? "text-bold" : ""}>{value}</span>
            },
            // {
            //     Header: t('Alpha Names.Type'),
            //     accessor: 'default',
            //     Cell: ({value, row}) => row.original.default ? <DisabledValue value='Default' /> : 'Custom'
            // },
            {
                Header: t('Alpha Names.Status'),
                accessor: 'status',
                className: 'text-right',
                Cell: ({value, row}) => {
                    const chipClassStatus = value == 1 ? "chipLike success" : value == 2 ? "chipLike primary" : "chipLike"
                    const chipClassTxt = value == 1 ? "Active" : value == 2 ? "On Review" : "Inactive"

                    const label = (
                        <div className={chipClassStatus}>
                            <span className="chipLikeLabel">{chipClassTxt}</span>
                        </div>
                    )

                    return value == 0 ? <DisabledValue value={label} /> : label
                }
            },
            {
                Header: 'Default',
                accessor: 'default',
                Cell: ({value, row}) => <div className="checkboxWrapper">
                    <IndeterminateCheckbox
                        isColumn={true}
                        disabled={!row.original.active}
                        checked={row.original.default}
                        onChange={() => {
                            if(!row.original.default) setDefaultAlphaName(row.original.id)
                        }}
                    />
                </div>
            },
        ]
    }, [t])

    const {setContextMenu} = useAppDispatch()

    useEffect(() => {
        setContextMenu([
            {
                label: 'Add New Alpha Name',
                icon: PlusCircle,
                action: () => EventEmitter.emit('AlphaNameCreate'),
                disabled: false
            },
        ])
    }, [setContextMenu])

    useEffect(() => {
        const onEvent = () => setShowCreateModal(true)

        const listener = EventEmitter.addListener('AlphaNameCreate', onEvent)
        return () => { listener.remove() }
    }, [])

    const alphaNamesRequest = useQuery(
        ['alphaNames', sorting],
        () => getAlphaNames(sorting),
        {keepPreviousData: true}
    )


    useEffect(() => {
        if (alphaNamesRequest.isSuccess) setList(alphaNamesRequest.data.data)
    },[alphaNamesRequest.isSuccess, alphaNamesRequest.data])

    //breadcrumbs
    useEffect(() => {
        setBreadcrumbs([
            {label: t('Alpha Names.Settings'), link: '/settings'},
            {label: t('Alpha Names.Alpha Name')}
        ])
        setSection('settings')
    }, [setBreadcrumbs, setSection, t])

    const handleLimitChange = useCallback((l) => {
        setPage(1)
        setLimit(l)
    }, [setPage, setLimit])

    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={list}
                loading={alphaNamesRequest.isLoading}
                fetching={alphaNamesRequest.isFetching}
                onSort={setSorting}
                emptyDataText={t('Alpha Names.No Alpha Names Found')}
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={handleLimitChange}
                disableSearchBlock={true}
            />
            <SaveAlphaNameModal
                open={showCreateModal}
                handleClose={() => {
                    setShowCreateModal(false)
                }}
            />
        </div>
    )
}

export default AlphaNames
