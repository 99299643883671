import React, {useEffect, useMemo, useState} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChangePassword from './ChangePassword';
import PersonalInformation from './PersonalInformation';
import SubscriptionPlan from './SubscriptionPlan';
import ProfileDetails from './ProfileDetails';
import {PrivateRoute} from '../../components/routes';
import {Link, Switch, useLocation, useRouteMatch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Transactions from './Transactions';
import Team from './team/Team';
import {useAuth} from '../../Contexts/AuthProvider';
import profileTabIcon from '../../assets/img-new/profileTabIcon.svg';
import tabSecurityIcon from '../../assets/img-new/tabSecurityIcon.svg';
import tabSubscriptionsIcon from '../../assets/img-new/tabSubscriptionsIcon.svg';

const Profile = () => {
    const [value, setValue] = useState(0)
    const { t } = useTranslation();
    const { path, url } = useRouteMatch();
    const { user } = useAuth();
    const location = useLocation()

    const TAB_DESCRIPTION = useMemo(() => {
        return [
            {value: 0, to: url, title: t('New Profile.Profile'), desc: t('New Profile.Change Personal Information'), icon: profileTabIcon},
            {value: 1, to: `${url}/security`, title: t('New Profile.Security'), desc: t('New Profile.Change Password'), icon: tabSecurityIcon},
            {value: 2, to: `${url}/subscriptions`, title: t('New Profile.Subscriptions'), desc: t('New Profile.Billing, Plans, Limits'), icon: tabSubscriptionsIcon},
            {value: 3, to: `${url}/transactions`, title: t('New Profile.Billing'), desc: t('New Profile.Billing Flow'), icon: tabSecurityIcon},
            {value: 4, to: `${url}/team`, title: t('Team.Team'), desc: t('Team.Team Management'), icon: tabSubscriptionsIcon},
    ]}, [])

    const renderLink = (to, title, description, img, hide = false) => {
        if (hide) return null;
        return (
                <Link to={to} className="pnItemContent">
                    <div className="profileItemContent">
                        <div>
                            <img src={img} alt=" " />
                        </div>
                        <div className="profileItemContent profileItemContent_title_description">
                            <div className="profileItemContent profileItemContent_title_description title">{title}</div>
                            <div className="profileItemContent profileItemContent_title_description description">{description}</div>
                        </div>
                    </div>
                </Link>
        );
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const selectedTab = TAB_DESCRIPTION.find(t => t.to.includes(location.pathname))
        setValue(selectedTab ? selectedTab.value : 0)
    }, [TAB_DESCRIPTION])

    const hide = user?.isTeamOperator() || !user?.canManageTeam()

    return (
        <div className="profilePageHolder">
            <div className="navPart">
                <div className="navPartContent">
                    <ProfileDetails handleChange={handleChange} />
                    <div className="profileNavHolder">
                        <Tabs value={value} onChange={handleChange}>
                            {TAB_DESCRIPTION.map(tab => <Tab key={`tabs_desc_${tab.value}`} label={renderLink(tab.to, tab.title, tab.desc, tab.icon)}/> )}
                        </Tabs>
                    </div>
                </div>
            </div>
            <div className="contentPart">
                <div className="contentPartContent">
                    <Switch>
                        <PrivateRoute path={`${path}`} component={PersonalInformation} exact />
                        <PrivateRoute path={`${path}/security`} component={ChangePassword} />
                        <PrivateRoute path={`${path}/subscriptions`} component={SubscriptionPlan} />
                        <PrivateRoute path={`${path}/transactions`} component={Transactions} />
                        <PrivateRoute path={`${path}/team`} component={Team} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default Profile;

